.authorizationAlert {
  text-align: center;
  font-size: 32px;
}

.adminHeaderDiv {
  text-align: center;
  padding-bottom: 30px;
}

.adminTable {
  margin-top: 30px;
}

/* Matchup Modal Section */

@media (min-width: 768px) {
  #adminMatchupModal {
    max-width: 50% !important;
  }
}

#adminMatchupModal > .modal-content .modal-header {
  font-size: 20px;
}

.adminMatchupModal {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 15px;
  /* min-height: 100%; */
  text-align: center;
  padding: 30px;
}

#adminMatchupModal > .modal-content {
  /* background: none;
  border: none; */
}

#adminMatchupModal > .modal-content .modal-header {
  /* display: none; */
}

.adminPickValues {
  color: green;
  font-weight: 900 !important;
}

.modifyMatchupButton {
  width: 100px !important;
}
