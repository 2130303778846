#menu {
  background-color: #39aabe !important;
}

#menu .logoLink {
  margin: auto;
  /* transform: translate(75%, 0%); */
  transform: translate(-175%, 0%);
}

@media (max-width: 768px) {
  #menu .logoLink {
    display: none;
  }
}

@media (min-width: 768px) {
  #menu .logoLinkMobile {
    display: none;
  }
}

#menu .logo {
  width: 50px;
  height: auto;
}

#menu a.nav-link {
  color: #ffffff;
  font-size: 24px;
}

.modalCloseBtnLink {
  display: block;
  text-align: right;
  padding-bottom: 15px;
  width: 25%;
  margin-right: 0;
  margin-left: auto;
}

.modalCloseBtn {
  margin-right: 0;
  margin-left: auto;
  width: 20px;
  height: auto;
}

.profileIconDiv {
  border: 1px solid #ffffff;
  border-radius: 50%;
  padding: 15px;
}

.profileIcon {
  width: 15px;
  height: 15px;
}

/* Login Section */

@media (min-width: 768px) {
  #loginModal {
    max-width: 50% !important;
  }
}

.loginDiv,
.forgotPwdDiv {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 15px;
  /* min-height: 100%; */
  text-align: center;
  padding: 30px;
}

#loginModal > .modal-content {
  background: none;
  border: none;
}

#loginModal > .modal-content .modal-header {
  display: none;
}

.loginModalHeader {
  padding-bottom: 30px;
}

.thirdPartySignInBtn {
  background-color: #f8f9fc;
  border: 1px solid #000000;
  color: #000000;
  padding: 15px;
  width: 100%;
  margin-bottom: 15px;
  border-radius: 7px;
}

.hrDiv {
  width: 100%;
  height: 20px;
  border-bottom: 3px solid grey;
  text-align: center;
}

.hrDiv > span {
  background-color: #ffffff;
  padding: 0 10px;
  margin-top: 7px;
  display: inline-block;
}

.loginForm {
  /* padding-top: 30px; */
}

.loginInput {
  margin: auto;
  display: block;
  width: 100%;
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 7px;
}

.loginButton {
  width: 100% !important;
  background-color: #39aabe !important;
  border: 1px solid #39aabe !important;
  color: #ffffff !important;
  transition-duration: 0.4s !important;
  padding: 10px !important;
  border-radius: 7px !important;
}

.loginButton:hover {
  background-color: #ffffff !important;
  color: #39aabe !important;
  transition-duration: 0.4s !important;
}

.loginButton:disabled {
  cursor: not-allowed !important;
}

.loginFlowLink {
  margin-bottom: 10px;
  display: block;
  font-size: 12px;
  text-align: left;
}

/* Mobile Toggle Menu */

.toggleMenu {
  border: none !important;
  box-shadow: none !important;
}

.toggleMenuDiv {
  width: 50px;
}

.toggleMenuLine {
  height: 5px;
  width: 100%;
  background-color: #ffffff;
  display: block;
}

.toggleMenuDiv span:nth-child(1),
.toggleMenuDiv span:nth-child(2) {
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  #menu .navbar-nav {
    text-align: center;
  }

  #menu .navbar-nav a {
    border-top: 2px solid #ffffff;
  }
}
