@media (max-width: 768px) {
  .homeAdSection {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px;
  }
}

.homeAdDiv {
  text-align: center;
  border: 5px solid #39aabe;
  padding: 20px 10px 0 10px;
  font-size: 16px;
}

.homeAdHeader {
  margin-bottom: 0;
}

.homeAdText {
  color: #287785;
}

.homeAdList {
  color: #287785;
  text-align: left;
}

.adHR {
  height: 5px;
  background-color: #39aabe;
  border: none;
  opacity: 0.5;
}

.homeCardSection {
  text-align: center;
  padding: 0 30px !important;
}

@media (max-width: 768px) {
  .homeCardSection {
    margin-bottom: 30px;
  }
}

/* Twitter Section */

.homeTwitterDiv {
  text-align: center;
  border: 5px solid #39aabe;
  padding: 20px 10px;
  margin-top: 15px;
  width: 100%;
  position: relative;
}

@media (max-width: 768px) {
  .homeTwitterDiv {
    display: none;
  }
}

.twitterLink {
  font-size: 24px;
  color: #287785;
}

/* Card Section */

.homeCardSection1 {
  /* border-right: 2.5px solid rgba(0, 0, 0, 0.25); */
}

.homeCardSection2 {
  border-left: 2.5px solid rgba(0, 0, 0, 0.25);
}

.homeCardHeader {
  font-size: 60px;
}

.sportsNavDiv {
  font-family: "Staatliches";
  font-weight: 700 !important;
}

.sportsNavButton {
  background: none;
  /* border: 1px solid #39aabe; */
  border: 2px solid #287785;
  color: #287785;
  border-radius: 7px;
  transition-duration: 0.4s;
  padding: 5px;
  width: 100%;
}

.sportsNavButton:hover {
  background: rgba(57, 170, 190, 0.5);
  /* background: #39aabe; */
  /* color: #ffffff; */
  transition-duration: 0.4s;
}

.sportsNavButtonActive {
  background: #39aabe;
  color: #ffffff;
  border: 2px solid #287785;
  border-radius: 7px;
  transition-duration: 0.4s;
  padding: 5px;
  width: 100%;
}

.sportsNavIconDiv {
  width: 25px;
  height: auto;
  display: block;
  margin: auto;
}

.homeCardSection button:nth-child(-n + 3) {
  margin-right: 15px;
}

form.searchInput {
  border: none;
  padding: 0;
  margin-top: 15px;
}

input.searchInput {
  width: 100%;
  border: 1px solid #287785;
  border-radius: 7px;
  color: #287785;
  display: block;
  padding: 9px 4px 9px 40px;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
}

input.search:focus {
  box-shadow: none;
  outline: none;
}

input.searchInput::placeholder {
  color: #287785 !important;
}

/* TRACK OUR BETS SECTION */

@media (max-width: 768px) {
  .betsSection {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 60px;
  }
}

.betsDiv {
  border: 5px solid #39aabe;
  padding: 20px;
  font-size: 16px;
}

.betsHeader {
  margin: 0;
  font-size: 20px;
}

.betsHr {
  height: 5px;
  background-color: #39aabe;
  border: none;
  margin: 0;
  opacity: 1;
}

.betsGameDiv {
  text-align: center;
  padding: 15px 0;
}

.betsGameDiv > p {
  font-size: 18px;
  margin: 0;
}

.betsLogo {
  width: 45px;
  height: 45px;
}

.betsLogoDiv > b {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 20px;
}

.checkmarkIcon,
.xIcon {
  width: 20px;
  height: auto;
}

.betsGameHr {
  height: 5px;
  background-color: grey;
  border: none;
  margin: 0;
  opacity: 1;
}

.betsWinRateDiv {
  text-align: center;
  padding: 15px 0 0 0;
}

/* Custom Bets Animation Counter Begin */

.betsWinPercentage {
  animation: counter 2s alternate ease-in-out;
  counter-reset: num var(--num);
  font-size: 90px;
  margin: 0;
}
.betsWinPercentage::after {
  content: counter(num) "%";
}

@keyframes counter {
  from {
    --num: 0;
  }
  to {
    --num: var(--success-rate);
  }
}

/* Custom Bets Animation Counter End */

.betsFullTrackerLink {
  text-decoration: none;
  color: #000000;
  transition-duration: 0.4s;
}

.betsFullTrackerLink:hover {
  color: #287785;
  transition-duration: 0.4s;
}

.storyText {
  margin-top: 30px;
}

/* CARD SECTION */

.cardCol {
  padding-top: 15px;
}

.cardDiv {
  border: 5px solid #39aabe;
  border-radius: 15px;
  padding: 15px;
  /* height: 275px; */
}

.cardTeamLogosDiv > b {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 20px;
}

.cardTeamLogo {
  width: 45px;
  height: 45px;
}

.cardButton {
  background-color: #39aabe;
  border: 1px solid #39aabe;
  color: #ffffff;
  width: 100%;
  border-radius: 7px;
  margin-bottom: 10px;
  padding: 5px 10px;
  transition-duration: 0.4s;
}

.cardButton:hover {
  background-color: #ffffff;
  color: #39aabe;
  transition-duration: 0.4s;
}

.cardFinalBanner {
  color: green;
}

/* Payment Section */

@media (min-width: 768px) {
  #paymentModal {
    max-width: 50% !important;
  }
}

#paymentModal > .modal-content .modal-header {
  font-size: 20px;
}

/* Matchup Modal Section */

@media (min-width: 768px) {
  #matchupModal {
    max-width: 50% !important;
  }
}

#matchupModal > .modal-content .modal-header {
  font-size: 20px;
}

.matchupModal {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 15px;
  /* min-height: 100%; */
  text-align: center;
  padding: 30px;
}

#matchupModal > .modal-content {
  /* background: none;
  border: none; */
}

#matchupModal > .modal-content .modal-header {
  display: none;
}

.matchupLogoDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.matchupLogoDiv div:nth-child(1) {
  margin-right: 15px;
}

.matchupLogoDiv div:nth-child(3) {
  margin-left: 15px;
}

.matchupLogo {
  width: 150px;
  height: 150px;
}

.matchupVS {
  font-size: 24px;
}

.matchupLogoDateTime {
  text-align: center;
}

.matchupExpertPickDiv {
  text-align: center;
  margin-top: 30px;
}

.matchupExpertPickHeader,
.matchupExpertPickValue {
  font-size: 24px;
}

.matchupExpertPickValue {
  color: green;
}

@media (min-width: 768px) {
  .matchupExpertPickHRMobile {
    display: none;
  }
}

.matchupFinalScore {
  font-size: 32px;
  color: green;
  margin: 0;
}

.matchupWinner {
  font-size: 32px;
  text-decoration: underline;
}

.matchPredictionResultsDiv {
  display: flex;
  justify-content: center;
}

.matchPredictionResultsHeader {
  font-size: 32px;
  text-decoration: underline;
}

.matchPredictionResultsDiv p {
  margin: 0 10px;
  font-size: 24px;
}

.matchupPointSpreadLogo {
  width: 30px;
  height: auto;
  margin-right: 5px;
}
